import React, {Component} from 'react';
import './App.css';
import axios from 'axios';
import credentials from "./credentials.js";


class ImageComponent extends Component {


  constructor(props) {
  		super(props);
  		this.state = {
        hovered: false
  		};
  	}


  toggleClickedIndexImage = (ele) => {
    this.props.toggleClickedIndexImage(ele);
  }

  renderUnblur = () => {
    this.setState({
      hovered: true
    })
  }

  renderDynamicStyle = (style) => {
    if(!this.state.hovered){
      return style
    }else{
      return {
        top: style.top,
        left: style.left,
        transform: style.transform,
        zIndex: 1
      }
    }
  };


  renderDynamicClassName = () => {
    if(!this.state.hovered){
      return "image_component_outer";
    }else{
      return `image_component_outer ${this.generateRandomAnim()}`;
    }
  }


  generateRandomAnim = () => {

    const classsNames = ["skewAnim", "polygonAnim"];
    let randomElement = classsNames[Math.floor(Math.random() * classsNames.length)];
    return randomElement;

  }


  render() {

    let ele = this.props.ele;
    let index = this.props.index;
    let style = this.props.styling;
    let imageSize = this.props.imageSize;
    let color = this.props.color;

    return (
      <div
      style={this.renderDynamicStyle(style)}
      className={this.renderDynamicClassName()}
      onMouseEnter={this.renderUnblur}
      onClick={() => this.toggleClickedIndexImage(ele)}
      key={index}>
         <img
            style={{width: imageSize.width}}
            src={ele.project_link}
         />
         <span style={{color: this.props.color}}>
            {ele.project_name}
         </span>
      </div>
    )
  };
};

export default ImageComponent;
