import React, {Component} from 'react';
import getRandomPathIntermezzo from './SvgPathsIntermezzo.js';
import './App.css';
import axios from 'axios';
import credentials from "./credentials.js";


class HeadlinesInfos extends Component {


  componentDidMount(){

    window.addEventListener('scroll', (e) => {
      let pageYOffset = window.pageYOffset;
      let pageYTotal = this.props.height;
      let height = document.getElementById('text_main')
      .getBoundingClientRect().width;
      // to optimize;
      let moveTextMain = document
      .getElementById("text_main")
      .style
      .transform = `translateX(-${pageYOffset/30}vw)`;
    });
  }


  toggleWebsiteSection = (ele) => {
    this.props.toggleWebsiteSection(ele);
  }

  render() {

  let ele = this.props.ele;
  let link = this.props.link;
  let index = this.props.index;
  let color = this.props.color;

    if(link === null){
      return (
        <div
          key={index}
          onClick={() => this.toggleWebsiteSection(ele)}
          className="main_info_text">
          <span>
            {ele}
         </span>
        </div>
      )
    }else{
      return (
        <div
          key={index}
          className="main_info_text">
          <a
            href={link}
            target="_blank"
            rel="noopener"
            rel="noreferrer">
             <span>
                {ele}
             </span>
         </a>
        </div>
      )
    }
  };
};

export default HeadlinesInfos;
