import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <div>
    <Favicon url="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1614734891/hamie_robitaille/favicon_2021.png" />
    <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
