import React, {Component} from 'react';
import _ from "lodash";
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import Time from 'react-time-format';
import { ShakeLittle, ShakeSlow, ShakeHorizontal } from 'reshake';
import ImageComponent from "./ImageComponent.js";
import HeadlinesInfos from "./HeadlinesInfos.js";
import './App.css';
import axios from 'axios';
import parse from 'html-react-parser';
import credentials from "./credentials.js";

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;


class App extends Component {

  constructor(props) {
  super(props);

    var today = new Date(),
    time = today.getHours();

   this.state = {
     mainData: null,
     selectedLan: "fr",
     currentDateTime: time,
     backgroundColor: null,
     textColor: null,
     invert: null,
     websiteSection: "index",
     projectSorting: null,
     allProjectImages: null,
     filteredProject: null,
     filteredMainData: null,
     isScrollBlocked: false,
     width: null,
     height: null,
     howManyProjectsHomepage: 0
   };
 };

 componentDidMount(){

   const {currentDateTime} = this.state;
   if (currentDateTime > 8
     && currentDateTime < 18) {
     document.body.style.backgroundColor = "white"
     this.setState({
       backgroundColor: "white",
       textColor: "black",
       invert: "invert(1)"
     })
   }else{
     document.body.style.backgroundColor = "black"
     this.setState({
       backgroundColor: "black",
       textColor: "white",
       invert: "invert(0)"
     })
   }

   this.setState({
     width: window.innerWidth,
     height: window.innerHeight
   });
   axios.get(credentials.googleSheet)
   .then((data) => {

     let mainData = data.data;

     this.setState({
       mainData
     }, () => {


      
      let numberOfMediaOnPage = 0;
  
       let allProjectImages = this.state.mainData
       .map((ele, index) => {
         if(ele.is_on_homepage === "oui"){

          numberOfMediaOnPage = numberOfMediaOnPage +1;

          console.log(`Cell #${index+2} for project ${ele.project_name} is: oui`);
          console.log(ele.project_link);

           return(
             <ImageComponent
                toggleClickedIndexImage={this.toggleClickedIndexImage}
                color={this.state.textColor}
                styling={this.generatePosition()}
                imageSize={this.generateImageSize()}
                ele={ele}
                index={index}
                {...this.state}
             />
           );
         }
       });

       // numberOfMediaOnPage: 50
       console.log(numberOfMediaOnPage, "numberOfMediaOnPage");


       let headlinesInfos = this.state.mainData
       .filter(ele => ele.content_section === "headlines")
       .map((ele, index) => {

         return (
           <HeadlinesInfos
              toggleWebsiteSection={this.toggleWebsiteSection}
              color={this.state.textColor}
              ele={ele.project_name}
              link={ele.project_link}
              index={index}
              {...this.state}
            />
         )
       })

       this.setState({
         allProjectImages,
         headlinesInfos,
       })
     })
     }).catch(function (error) {
   })
 };

 componentDidUpdate(prevProps, prevState) {

   const {filteredProject, filteredMainData} = this.state;

   if(filteredMainData !== prevState.filteredMainData){
     this.setState({
       isScrollBlocked: !this.state.isScrollBlocked
     }, () => {

       const {isScrollBlocked} = this.state;
       if(isScrollBlocked){
         document.getElementById("body").style.overflow = "hidden";
       }else{
         document.getElementById("body").style.overflow = "scroll";
       }
     })
   }
 }

 renderDynamicStyle = (ele) => {

   const {currentDateTime, textColor, invert, backgroundColor} = this.state;

   if(ele === "shuffle_button"){
     return {
       filter: invert
     }
   }else if (ele === "about_links_outer"){
     return {
       borderTop: `2px solid ${textColor}`
     }
  }else if(ele === "lightbox_headlines"){
    return {
      color: textColor,
      borderBottom: `2px solid ${textColor}`
    }
  }else if(ele === "lightbox"){
     return {
       backgroundColor: backgroundColor,
       border: `2px solid ${textColor}`
     }
   }else{
     return {
       color: textColor
     }
   }
 }



 toggleClickedIndexImage = (ele) => {

   this.setState({
     filteredProject: ele.project_name
   }, () => {

     let filteredProject = this.state.filteredProject;
     let filteredMainData = this.state.mainData.filter(ele => ele.project_name === filteredProject);
     let bluringBackground = document.getElementsByClassName("image_component_outer");

     this.setState({
       filteredMainData
     })

   })
 };


 toggleWebsiteSection = (ele) => {

   this.setState({
     websiteSection: ele
   }, () => {
     let imgOutter = document.getElementsByClassName("image_component_outer");
     let textMain = document.getElementById("text_main");
     let divs = [...imgOutter];
     if(this.state.websiteSection === "About"){

       document.getElementById("body").style.overflow = "hidden";
       textMain.style.display = "none";

     }else{

       document.getElementById("body").style.overflow = "scroll";
       textMain.style.display = "block";
     }
   })
 }

 generatePosition = () => {
   return {
     top: `${this.getRandomInt(0, 500)}vh`,
     left: `${this.getRandomInt(0, 70)}vw`,
     transform: `rotate(${this.getRandomInt(2, 8)}deg)`,
     mixBlendMode: "unset"
   }
 };

 generateImageSize = () => {
   return {
     width: `${this.getRandomInt(20, 60)}vw`,
   }
 }

  getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }


  shuffleProjects = () => {
    this.setState({
      allProjectImages: []
    }, () => {
      const {width} = this.state;

      if(width > 600){
        let allProjectImages = this.state.mainData
        .map((ele, index) => {

          if(ele.is_on_homepage === "oui"){
            return(
              <ImageComponent
                 toggleClickedIndexImage={this.toggleClickedIndexImage}
                 styling={this.generatePosition()}
                 imageSize={this.generateImageSize()}
                 ele={ele}
                 index={index}
                 {...this.state}
              />
            );
          }  
        });

        this.setState({
          allProjectImages
        })

      }else{

        let allProjectImages = _.shuffle(this.state.mainData)
        .map((ele, index) => {
          return(
            <ImageComponent
               toggleClickedIndexImage={this.toggleClickedIndexImage}
               styling={this.generatePosition()}
               imageSize={this.generateImageSize()}
               ele={ele}
               index={index}
               {...this.state}
            />
          );
        });

        this.setState({
          allProjectImages
        })
      }
    })
  }


  renderFilteredMainDataInfo = () => {

    let filteredMainDataInfo = this.state.filteredProject;

    return (
      <div
        className="lightbox_headlines">
        <h1 style={this.renderDynamicStyle("lightbox_headlines")}>
          {filteredMainDataInfo}
        </h1>
      </div>
    )
  }

  renderFilteredMainDataDesc = () => {
    const {filteredMainData} = this.state;
    let descArray = filteredMainData.filter((ele) => {
      return ele.project_info;
    });
    let desc = descArray[0].project_info;

    return (
      <div
        className="lightbox_description">
        <h1 style={this.renderDynamicStyle("lightbox_description")}>
          {desc}
        </h1>
      </div>
    )
  };

  renderFilteredMainDataCredits = () => {
    const {filteredMainData} = this.state;
    let creditsArray = filteredMainData.filter((ele) => {
      return ele.project_credits;
    });

    if(creditsArray.length > 0){
      let credits = creditsArray[0].project_credits;
      return (
       <div className="lightbox_description">
         <h1 style={this.renderDynamicStyle("lightbox_description")}>
           {credits}
         </h1>
       </div>
     )
    }
  }



  renderHeader = () => {
    return (
      <div id="header_main" className="header_component_main_outer">
      <header>
            <div>
              <h1 style={this.renderDynamicStyle("headerH1")}>
                Hamie Robitaille
              </h1>
            </div>
            <div className="last">
              <div>
                <h1 style={this.renderDynamicStyle("headerH1")}>
                  Montréal, QC
                </h1>
              </div>
            </div>
            {this.renderShuffleButton()}
      </header>
      </div>
    )
  };

  renderShuffleButton = () => {

    if(this.state.websiteSection !== "index"){
      return null;
    }

    return (
      <div
          style={this.renderDynamicStyle("shuffle_button")}
          onClick={this.shuffleProjects}
          className="shuffle_button">
          <img
          src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1615349134/hamie_robitaille/shuffle_editorial.svg" />
      </div>
    )
  }

  unfilterMainData = () => {
    this.setState({
      filteredMainData: null
    })
  }

  filteredProject = () => {
    if(!this.state.filteredMainData){
      return null;
    }
      let filteredMainDataComponents = this.state.filteredMainData
      .map((ele, index) => {
        let link = ele.project_link;

        if(link.includes("cloudinary")){
          return (
            <div>
              <img src={ele.project_link} />
            </div>
          )
        }else{
          if(link.length === 9){
            return (
              <div className="lightbox_iframe_container">
                <Vimeo
                  video={link}
                  />
              </div>
            )
          }else{
            return (
              <div className="lightbox_iframe_container">
                <YouTube
                  videoId={link}
                  />
              </div>
            )
          }
        }

      });

      return (
        <div id="image_filtered"  className="image_filtered_main_outer">
        <section
          onClick={this.unfilterMainData}
          className="lightbox_container">
            <div
              style={this.renderDynamicStyle("lightbox")}
              id="lightbox"
              className="lightbox">
              {this.renderFilteredMainDataInfo()}
              {filteredMainDataComponents}
              {this.renderFilteredMainDataDesc()}
              {this.renderFilteredMainDataCredits()}
            </div>
        </section>
        </div>
      )
  };


renderIndexPage = () => {
    return (
      <section>
        <div id="text_main" className="text_component_main_outer">
          {this.state.headlinesInfos}
        </div>
        {this.renderImage()}
     </section>
    )
}

renderImage = () => {
  let {websiteSection} = this.state;
  return (
    <div id="image_main"  className="image_component_main_outer">
     {this.state.allProjectImages}
   </div>
  )
};

renderAbout = () => {

  const {mainData, selectedLan} = this.state;
  let selectedBio = `bio_${selectedLan}`;
  let bio = mainData.filter((ele) => {
    return ele.project_name === selectedBio
  })

  if(this.state.websiteSection !== "About"){
    return null;
  }
  return (
    <div className="about_container">
    <div className="about_container_cta" />
        {this.renderSelectedLanBio(bio)}
        {this.renderSelectedLinksBio(bio)}
        <div className="about_cta">
          <h2
            style={this.renderDynamicStyle("about_cta")}
            onClick={() => this.toggleWebsiteSection("index")}>X</h2>
            {this.renderSelectedLan()}
        </div>
    </div>
  )
};


renderSelectedLinksBio = (bio) => {

  let selectedBioLinksText = bio[0].bio_links_text.split("¬")
  let selectedBioLinksUrl = bio[0].bio_links_urls.split("¬");



  return (
    <div
      style={this.renderDynamicStyle("about_links_outer")}
      className="about_links_outer">
      <div
        className="about_links">
        <a
          style={this.renderDynamicStyle("about_links")}
          target="_blank" rel="noopener noreferrer"
          href={selectedBioLinksUrl[0]}>
          {selectedBioLinksText[0]}
        </a>
        <a
          style={this.renderDynamicStyle("about_links")}
          target="_blank" rel="noopener noreferrer"
          href={selectedBioLinksUrl[1]}>
          {selectedBioLinksText[1]}
        </a>
      </div>
      <div className="about_links">
      <a
        style={this.renderDynamicStyle("about_links")}
        target="_blank" rel="noopener noreferrer"
        href={selectedBioLinksUrl[2]}>
          {selectedBioLinksText[2]}
        </a>
        <a
          style={this.renderDynamicStyle("about_links")}
          target="_blank" rel="noopener noreferrer"
          href={selectedBioLinksUrl[3]}>
          {selectedBioLinksText[3]}
        </a>
      </div>
      <div className="about_links">
      <a
        style={this.renderDynamicStyle("about_links")}
        target="_blank" rel="noopener noreferrer"
        href={selectedBioLinksUrl[4]}>
        {selectedBioLinksText[4]}
      </a>
      </div>
    </div>
  )
}

renderSelectedLanBio = (bio) => {
  return (
    <h1
      style={this.renderDynamicStyle("bio_content")}>
      {parse(bio[0].bio_content)}
    </h1>
  )
}


renderSelectedLan = () => {
  const {selectedLan} = this.state;
  if(selectedLan === "fr"){
    return (
      <h2
      style={this.renderDynamicStyle("selected_lan")}
      onClick={() => this.toggleLanguage("en")}>en</h2>
    )
  }else{
    return (
      <h2
      style={this.renderDynamicStyle("selected_lan")}
      onClick={() => this.toggleLanguage("fr")}>fr</h2>
    )
  }
};

toggleLanguage = (en) => {
  this.setState({
    selectedLan: en
  })
}


  render() {
    if(!this.state.allProjectImages){
      return (
        <div>
        </div>
      )
    }
      return (
        <div
          className="App">
           {this.renderHeader()}
           {this.renderAbout()}
           {this.renderIndexPage()}
           {this.filteredProject()}
        </div>
      );
  };
};

export default App;
